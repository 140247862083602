<template>
  <div v-if="PageScript" class="container">
    <van-tabs sticky class="tab-cls">
      <van-tab title="基本信息">
        <template v-for="formItem in pageLayout">
          <div v-if="formItem.fmCode !== 'images'" :key="formItem.fmCode" class="form h-shadow-sm">
            <div class="form-title">
              <span class="title">{{ formItem.fmName }}</span>
            </div>
            <div class="form-content">
              <HYFormView :ref="formItem.fmCode + 'FormRef'" :config="formItem" :form="pageForm[formItem.fmCode]" :detailForm="detailForm"> </HYFormView>
            </div>
          </div>
        </template>
      </van-tab>
      <van-tab title="影像信息">
        <div v-if="fileLayout" class="form h-shadow-sm">
          <div class="form-title h-flex h-flex-wrap h-row-between">
            <div class="title">影像信息</div>
          </div>
          <div class="form">
            <HYFormView :config="{ formItems: fileLayout }" :form="pageForm['images']" :detailForm="detailForm"> </HYFormView>
          </div>
        </div>
      </van-tab>
      <van-tab v-if="wfInstanceId" title="审核进度">
        <div v-if="auditList && auditList.length > 0" class="form h-shadow-sm">
          <div class="form-title">
            <div class="title">审核进度</div>
          </div>
          <div class="form-content">
            <van-steps direction="vertical" :active="auditList.length - 1" active-color="#57ccc2" inactive-icon="checked">
              <template v-for="(item, index) in auditList">
                <van-step :key="index">
                  <div>{{ getTimeStamp(item, index) }}</div>
                  <div class="h-text-desc-color">
                    <template v-if="index === 0">
                      <div class="h-m-v-8">
                        <label>申请人：</label>
                        {{ item.checkerName }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="h-m-v-8">
                        <label>{{ getCheckerName(item) }}：</label>
                        {{ item.checkerName }}
                      </div>
                      <div v-if="item.nodeCode !== 'First'" class="h-m-v-8">
                        <label>审核结果：</label>
                        {{ item.checkResult }}
                      </div>
                      <div v-if="item.nodeCode !== 'First'" class="h-m-v-8">
                        <label>审核意见：</label>
                        <div style="line-height: 16px;display: inline-block;">{{ item.checkSuggest }}</div>
                      </div>
                    </template>
                  </div>
                </van-step>
              </template>
            </van-steps>
          </div>
        </div>
        <div v-else>
          <div class="none-tip">
            <van-image :src="require('@/assets/images/none_data.jpg')" />
            <div class="h-font-lg h-text-secondary-color">暂无审核记录！</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import HYFormView from "@/components/FormView.vue";
import layoutData from "@/views/product/layout/orderData";
import scriptLib from "@/views/product/layout/orderScript";
import { request } from "@/api/service";
import dayjs from "dayjs";
import { mapState } from "vuex";
export default {
  name: "ProductIndex",
  components: { HYFormView },
  provide() {
    return {
      pageContext: this,
      pageForm: this.pageForm,
      pageLayout: this.pageLayout,
      pageLayoutMap: this.pageLayoutMap,
      PageScript: this.PageScript
    };
  },
  data() {
    return {
      productData: null,
      fileLayout: [],
      pageForm: {},
      pageLayout: [],
      pageLayoutMap: {},
      PageScript: scriptLib.call(this),
      detailForm: {},
      wfInstanceId: null,
      auditList: []
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("user", ["info"]),
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      return pageScriptObj;
    }
  },
  async created() {
    await this.initData();
    this.productData = await layoutData.call(this);
    this.init();
    this.initFileLayout();
  },
  methods: {
    dayjs,
    init() {
      const { id } = this.$route.query;
      this.scriptObj.serviceToPage(id).then(res => {
        this.detailForm = res;
        if (!(res && res.wfInstanceId)) {
          return false;
        }
        this.wfInstanceId = res.wfInstanceId;
        this.getAuditList(res.wfInstanceId);
      });
    },
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initFileLayout() {
      if (!(this.pageLayout && this.pageLayout.length > 0)) {
        return false;
      }
      const filePageLayout = this.pageLayout.filter(res => res.fmCode === "images");
      if (!(filePageLayout && filePageLayout.length > 0)) {
        return false;
      }
      filePageLayout.forEach(item => {
        if (!item.formItems) {
          return false;
        }
        this.fileLayout = this.fileLayout.concat(item.formItems);
      });
    },
    getAuditList(wfInstanceId) {
      if (!wfInstanceId) {
        return false;
      }
      request({
        url: `/afis-carservice/workflow/task/history/instance/${wfInstanceId}`,
        method: "get"
      })
        .then(res => {
          this.auditList = res.reverse();
        })
        .catch(error => {});
    },
    getTimeStamp(item, index) {
      const nodeCode = item.nodeCode;
      const checkerTimeText = nodeCode === "First" ? "提交时间" : "审核时间";
      return `${index === 0 ? "申请时间" : checkerTimeText}：${dayjs(item.checkerTime).format("YYYY-MM-DD HH:mm")}`;
    },
    getCheckerName(item) {
      const nodeCode = item.nodeCode;
      let checkerName = item.nodeName;
      switch (nodeCode) {
        case "First":
          if (item.checkSuggest === "主动撤回") {
            checkerName = "主动撤回";
          } else {
            checkerName = "重新提交";
          }
          break;
      }
      return checkerName;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
::v-deep .van-checkbox {
  display: inline-block;
}
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    .title {
      border-left: 5px solid $primary-color;
      padding: 0 8px;
    }
  }
}
.fail-reason {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 微软雅黑;
  color: rgb(102, 102, 102);
  line-height: 30px;
  padding: 0px 20px;
  word-wrap: break-word;
  padding-bottom: 20px;
}
.submitBtn {
  height: 44px;
  .fixed {
    border-top: 1px solid $border-color;
    position: fixed;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.agreement ::v-deep {
  line-height: 12px;
  .van-checkbox__icon--disabled .van-icon {
    background-color: #fff;
  }
  .van-checkbox__icon--checked .van-icon {
    color: #fff;
    border-color: #57ccc2;
    background-color: #57ccc2;
  }
}
.none-tip {
  text-align: center;
  margin: 10px;
  border-radius: 5px;
  background-color: $white;
  ::v-deep .van-image {
    margin-top: 20px;
  }
}
</style>
